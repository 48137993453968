import axios from '@axios'

export default {
  namespaced: true,
  state: {
    menus: [],
    menu: null,
    stations: [],
    locations: []
  },
  getters: {},
  mutations: {
    SET_MENUS(state, data) {
      state.menus = data
    },
    SET_MENU(state, item) {
      state.menu = item
    },
    ADD_MENU(state, item) {
      state.menus.push(item)
    },
    EDIT_MENU(state, item) {
      const index = state.menus.findIndex(m => m._id === item.id)
      if (index >= 0) {
        Object.assign(state.menus[index], item)
      }
    },
    REMOVE_MENU(state, id) {
      const index = state.menus.findIndex(m => m._id === id)
      if (index >= 0) {
        state.menus.splice(index, 1)
      }
    },
    SET_STATIONS(state, data) {
      state.stations = data
    },
    SET_LOCATIONS(state, data) {
      state.locations = data
    }
  },
  actions: {
    getItems ({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`menus`)
          .then(response => {
            commit("SET_MENUS", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getItem ({commit}, menu) {
      return new Promise((resolve, reject) => {
        axios.get(`menus/${menu}`)
          .then(response => {
            commit("SET_MENU", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addItem ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`menus`, payload)
          .then(response => {
            commit("ADD_MENU", response.data.item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editItem ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`menus/${payload._id}`, payload)
          .then(response => {
            commit("EDIT_MENU", response.data.item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteItem ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`menus/${id}`)
          .then(response => {
            commit("REMOVE_MENU", id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getActiveMenus ({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`active-menus`)
          .then(response => {
            commit("SET_MENUS", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getStations ({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`stations`)
          .then(response => {
            commit("SET_STATIONS", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getLocations ({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`station-locations`)
          .then(response => {
            commit("SET_LOCATIONS", response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
